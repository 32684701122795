import BaseService from "./base.service";

class BlogsService {

    blogs(page = null, search = null) {
        let params = [];
        if(search) {
            params.push({'key':'q', 'value':search});
        }
        return BaseService.apiGetRequest('blog', page, params);
    }
    get(id) {
        return BaseService.apiGetRequest('blog/'+id);
    }
    delete(blog) {
		return BaseService.apiPostRequest('blog/delete/', blog);
    }
    add(blog) {
        return BaseService.apiPostRequest('blog/add', blog);
    }
    update(id, blog) {
        return BaseService.apiPostRequest('blog/'+id, blog);
    }

    search(page=null) {
        return BaseService.apiGetRequest('blog/search', page);
    }
}
export default new BlogsService();
